/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: acrom-bold;
    src: url(assets/fonts/Acrom/Acrom-Bold.woff) format("opentype");
}

@font-face {
    font-family: acrom-extrabold;
    src: url(assets/fonts/Acrom/Acrom-ExtraBold.woff) format("opentype");
}

@font-face {
    font-family: acrom-light;
    src: url(assets/fonts/Acrom/Acrom-Light.woff) format("opentype");
}

@font-face {
    font-family: acrom-medium;
    src: url(assets/fonts/Acrom/Acrom-Medium.woff) format("opentype");
}

@font-face {
    font-family: acrom-regular;
    src: url(assets/fonts/Acrom/Acrom-Regular.woff) format("opentype");
}

@font-face {
    font-family: acrom-thin;
    src: url(assets/fonts/Acrom/Acrom-Thin.woff) format("opentype");
}

.max-width-global {
    max-width: 1280px;
}

.max-margin-global {
    margin: 0 auto !important;
}


/* You can add global styles to this file, and also import other style files */